<template>
  <a-layout :style="{ minHeight: '100vh' }">
    <a-layout-sider
      v-if="isDesktop"
      v-model:collapsed="collapsed"
      collapsible
      breakpoint="lg"
      collapsedWidth="100"
      key="desktop-sider"
    >
      <div class="logo">
        <!-- <a-tooltip v-if="collapsed" :title="t('welcome')">
          <h3>{{ t("welcome") }}</h3>
        </a-tooltip>
        <h3 v-else>{{ t("welcome") }}</h3> -->
      </div>
      <a-menu theme="dark" mode="inline" @click="handleMenuClick">
        <template v-for="route in menuRoutes">
          <a-sub-menu
            v-if="route.children && route.children.length"
            :key="route.path"
          >
            <a-menu-item
              v-for="child in route.children"
              :key="child.path"
              :to="child.path"
              :title="t(child.meta.label)"
              :class="{
                active:
                  currentRoutePath &&
                  child.path &&
                  currentRoutePath.includes(child.path),
              }"
            >
              <template v-if="collapsed">
                <component :is="getIconComponent(child.meta.icon)" />
              </template>
              <template v-else>
                {{ t(child.meta.label) }}
              </template>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-else
            :key="`${route.path}`"
            :to="route.path"
            :title="t(route.meta.label)"
            :class="{
              active:
                currentRoutePath &&
                route.path &&
                currentRoutePath.includes(route.path),
            }"
          >
            <template v-if="collapsed">
              <component :is="getIconComponent(route.meta.icon)" />
            </template>
            <template v-else>
              {{ t(route.meta.label) }}
            </template>
          </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header">
        <div class="header-left">
          <Dropdown v-if="!isDesktop" key="mobile-dropdown">
            <template #default>
              <a>
                <MenuUnfoldOutlined />
              </a>
            </template>
            <template #overlay>
              <a-menu theme="dark" mode="vertical" @click="handleMenuClick">
                <template v-for="route in menuRoutes">
                  <a-sub-menu
                    v-if="route.children && route.children.length"
                    :key="route.path"
                  >
                    <a-menu-item
                      v-for="child in route.children"
                      :key="child.path"
                      :to="child.path"
                      :title="t(child.meta.label)"
                      :class="{
                        active:
                          currentRoutePath &&
                          child.path &&
                          currentRoutePath.includes(child.path),
                      }"
                    >
                      {{ t(child.meta.label) }}
                    </a-menu-item>
                  </a-sub-menu>
                  <a-menu-item
                    v-else
                    :key="`${route.path}`"
                    :to="route.path"
                    :title="t(route.meta.label)"
                    :class="{
                      active:
                        currentRoutePath &&
                        route.path &&
                        currentRoutePath.includes(route.path),
                    }"
                  >
                    {{ t(route.meta.label) }}
                  </a-menu-item>
                </template>
              </a-menu>
            </template>
          </Dropdown>
        </div>
        <img class="logoPic" :src="logoUrl" alt="" />
        <!-- Language and Logout Buttons -->
        <div class="header-actions">
          <Dropdown>
            <template #default>
              <a class="language-button" @click="toggleLanguageMenu">
                <img
                  src="@/assets/icons/language.svg"
                  alt="language"
                  class="language-icon"
                />
              </a>
            </template>
            <template #overlay>
              <a-menu @click="handleLanguageChange">
                <a-menu-item
                  key="en"
                  :class="{ activeLanguage: currentLanguage === 'en' }"
                >
                  English
                </a-menu-item>
                <!-- <a-menu-item
                  key="zh"
                  :class="{ activeLanguage: currentLanguage === 'zh' }"
                >
                  中文
                </a-menu-item> -->
                <a-menu-item
                  key="de"
                  :class="{ activeLanguage: currentLanguage === 'de' }"
                >
                  Deutsch
                </a-menu-item>
                <a-menu-item
                  key="fr"
                  :class="{ activeLanguage: currentLanguage === 'fr' }"
                >
                  Französisch
                </a-menu-item>
              </a-menu>
            </template>
          </Dropdown>
          <Dropdown>
            <template #default>
              <a class="user-icon" @click="toggleUserMenu">
                <img
                  src="@/assets/icons/user.svg"
                  alt="user"
                  class="user-avatar"
                />
              </a>
            </template>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  {{ userInfo?.username }}
                </a-menu-item>
                <a-menu-item @click="logoutFunc">
                  {{ t("logout") }}
                </a-menu-item>
                <a-menu-item @click="openDeleteModal">
                  {{ t("deleteAccount") }}
                </a-menu-item>
              </a-menu>
            </template>
          </Dropdown>
        </div>
      </a-layout-header>

      <a-layout-content style="margin: 16px">
        <slot />
      </a-layout-content>
      <!-- 注销账户弹框 -->
      <a-modal
        v-model:visible="deleteModalVisible"
        :title="t('deleteAccount')"
        @ok="confirmDeleteAccount"
        @cancel="closeDeleteModal"
        :okText="t('button.confirm')"
        :cancelText="t('button.cancel')"
        :confirmLoading="isDeleting"
      >
        <a-form
          ref="deleteFormRef"
          :model="deleteForm"
          :rules="deleteFormRules"
          layout="vertical"
        >
          <a-form-item
            :label="t('form.label.password')"
            name="password"
            required
          >
            <a-input-password
              v-model:value="deleteForm.password"
              :placeholder="t('form.placeholder.password')"
              allow-clear
            />
          </a-form-item>
        </a-form>
      </a-modal>

      <a-layout-footer style="text-align: center">
        {{
          officialWebsiteSource && officialWebsiteSource === "elite"
            ? "Sunseeker Elite "
            : officialWebsiteSource && officialWebsiteSource === "tech"
            ? "Sunseeker "
            : ""
        }}
        ©{{ new Date().getFullYear() }}
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import { useResponsive } from "@/hooks/useResponsive";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ref, watch, onMounted, reactive, computed } from "vue";
import { mapState, mapActions } from "vuex";
import { MenuUnfoldOutlined } from "@ant-design/icons-vue";
import Icon from "@ant-design/icons-vue";
import * as Icons from "@ant-design/icons-vue";
import { Dropdown, message } from "ant-design-vue";
import { logout, deleteAccount } from "@/api/user"; // 引入退出登录API
import { useStore } from "vuex";
import { getOfficialWebsiteSource } from "@/utils/config";
import {
  getToken,
  setToken,
  removeToken,
  setUser,
  clearUser,
  getUser,
  clearMenu,
  setMenu,
  getMenu,
} from "@/utils/auth";
const eliteLogo = require("@/assets/images/eliteLogo.png");
const techLogo = require("@/assets/images/techLogo.png");
export default {
  name: "ResponsiveLayout",
  components: {
    MenuUnfoldOutlined,
    Dropdown,
    Icon,
  },
  data() {
    return {
      deleteModalVisible: false,
      deleteForm: {
        password: "",
      },
      isDeleting: false,
    };
  },
  computed: {
    ...mapState("language", ["currentLanguage"]), // 获取 currentLanguage 状态
  },
  methods: {
    ...mapActions("language", ["setLanguage"]),
    ...mapActions("auth", ["removeUser"]),
    async logoutFunc() {
      try {
        await logout().then((res) => {
          console.log("调用方拿到的返回", res);
          if (res?.code === 0) {
            removeToken(); // 清除存储的用户信息
            this.$store.dispatch("auth/removeUser");
            this.$router.push("/login"); // 跳转到登录页面
            message.success(this.$t("logoutSuccess")); // 提示退出成功
          }
        }); // 调用退出登录接口
      } catch (error) {}
    },
    //注销用户
    async deleteAccountFunc() {},
    async deleteAccount() {
      try {
        await deleteAccount().then((res) => {
          console.log("调用方拿到的返回", res);
        });
      } catch (error) {}
    },
    openDeleteModal() {
      this.deleteModalVisible = true;
    },
    closeDeleteModal() {
      this.deleteModalVisible = false;
      this.resetDeleteForm();
    },
    resetDeleteForm() {
      this.$refs.deleteFormRef.resetFields();
    },
    async confirmDeleteAccount() {
      this.$refs.deleteFormRef
        .validate()
        .then(async () => {
          this.isDeleting = true;
          console.log("进入校验");
          deleteAccount({ oldPwd: this.deleteForm.password })
            .then((res) => {
              if (res?.code === 0) {
                message.success(this.$t("response.deleteAccountSuccess"));
                this.closeDeleteModal();
                removeToken();
                this.$store.dispatch("auth/removeUser");
                this.$router.push("/login");
              }
            })
            .catch(() => {
              this.isDeleting = false;
            });
        })
        .catch(() => {
          console.log("密码校验失败");
          this.isDeleting = false;
        });
    },
    getIconComponent(iconName) {
      // 动态返回对应的图标组件
      return Icons[iconName] || null;
    },
  },
  setup() {
    const { t, locale } = useI18n();
    const userInfo = ref(getUser() || {}); // 获取用户信息
    const logoUrl = ref("");
    const officialWebsiteSource = ref(getOfficialWebsiteSource());
    const deleteFormRules = computed(() => {
      return {
        password: [
          {
            required: true,
            message: t("form.validationPasswordRequired"),
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/,
            message: t("form.password.validate"),
            trigger: "blur",
          },
        ],
      };
    });

    const store = useStore(); // 获取 Vuex store
    const { isMobile, isTablet, isDesktop } = useResponsive();
    const router = useRouter();
    const currentRoute = useRoute();
    const currentRoutePath = ref(currentRoute.path);
    const menuRoutes = router.options.routes.filter(
      (route) =>
        route.meta &&
        route.meta.label &&
        route.path !== "/" &&
        !route.meta.hideMenu
    );
    //如果查询到token，且正在
    const refreshUserInfo = () => {
      if (getToken()) {
        //刷新用户的信息
      } else {
        //跳转到登录页面，并且清空存储用户的信息及菜单
        store.dispatch("auth/removeUser");
        router.push("/login");
      }
    };
    // 绑定 switchLanguage 方法
    const handleLanguageChange = ({ key }) => {
      locale.value = key;
      store.dispatch("language/setLanguage", key);
    };

    watch(
      () => currentRoute.path,
      (newPath) => {
        currentRoutePath.value = newPath;
      }
    );
    const handleMenuClick = ({ key }) => {
      if (key !== currentRoute.path) {
        router.push(key);
      }
    };

    const toggleMobileMenu = (value) => {
      console.log("toggleMobileMenu", value);
      collapsed.value = !collapsed.value;
    };

    const collapsed = ref(false);
    onMounted(() => {
      logoUrl.value =
        officialWebsiteSource.value === "tech"
          ? techLogo
          : officialWebsiteSource.value === "elite"
          ? eliteLogo
          : "";
    });

    return {
      isMobile,
      isTablet,
      isDesktop,
      userInfo,
      collapsed,
      logoUrl,
      officialWebsiteSource,
      t,
      handleLanguageChange,
      handleMenuClick,
      menuRoutes,
      deleteFormRules,
      currentRoutePath,
      toggleMobileMenu,
    };
  },
};
</script>

<style lang="less">
.logo {
  height: 26px;
  margin: 16px;
  color: white;
  text-align: center;
  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.logoPic {
  width: 220px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
.language-button,
.user-icon {
  display: flex;
  align-items: center;
}
.language-icon,
.user-avatar {
  width: 20px;
  margin-right: 8px;
}
.ant-layout-header {
  line-height: unset !important;
  padding-inline: 20px !important;
}
.header-left {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.mobile-menu {
  position: absolute;
  left: 0;
  top: 56px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.85);
  width: 200px;
}
.ant-menu-item-selected {
  background-color: var(--primary-color) !important;
}
.active {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-color: var(--primary-color) !important;
}
.activeLanguage {
  background-color: var(--primary-color) !important;
  // background-color: color-mix(in srgb, var(--primary-color), transparent 30%);
  color: white !important;
  border-color: var(--primary-color) !important;
}
.ant-btn-default:hover {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.active:hover {
  color: #fff !important;
}
.ant-menu-item-selected,
.ant-menu-item.active {
  background-color: var(--primary-color) !important;
}
.ant-dropdown-menu-item:hover {
  background-color: var(--primary-color-hover) !important;
}
</style>
